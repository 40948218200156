<template>
  <form-view
    v-loading="loading"
    type="verify"
    abortable
    :abort-method="abortMethod"
    :verify-method="verifyMethod"
  >
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="自编号" prop="carId">
          <el-select disabled filterable v-model="dataForm.carId">
            <el-option v-for="s in carList" :key="s.id" :label="s.number" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="车牌号码" prop="licensePlate">
          <el-input disabled v-model="dataForm.licensePlate"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="车辆型号" prop="carSize">
          <el-input disabled v-model="dataForm.carSize"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="加油数量" prop="fillingNumber">
          <el-input disabled type="number" v-model="dataForm.fillingNumber"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="油单价" prop="unitPrice">
          <el-input disabled type="number" v-model="dataForm.unitPrice"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="油总价" prop="totalPrice">
          <el-input disabled v-model="dataForm.totalPrice"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row style="margin-bottom:20px;">
      <image-uploader disabled :images="dataForm.images || []" no-title></image-uploader>
    </el-row>
    <div class="section-title-row">
      <div class="title">操作历史</div>
      <div class="line"></div>
    </div>
    <div class="record-area">
      <div class="record-row" v-for="(r, i) in records" :key="i">{{r}}</div>
    </div>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'
import dayjs from 'dayjs'
import { operationTypes } from '@/utils/constants'

export default {
  name: 'car-oil-verify',

  components: { FormView, ImageUploader },

  data () {
    return {
      loading: false,
      dataForm: {},
      carList: [],
      records: [],
      id: null
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.id = this.$route.query.id
      this.loading = true
      if (id) {
        let data = await this.$http({
          url: this.$http.adornUrl('/car/list'),
          method: 'post',
          data: { carType: 1 }
        })
        this.carList = data.datas
        data = await this.$http({
          url: this.$http.adornUrl('/car/filling/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          this.dataForm.testsType = parseInt(this.dataForm.testsType)
          this.dataForm.testsDate = dayjs(this.dataForm.testsDate).valueOf()
          if (this.dataForm.image) {
            this.dataForm.images = this.dataForm.image.split(',').map(j => ({ url: '/files' + j }))
            this.dataForm.imageList = this.dataForm.images.map(j => j.url.substring('/files'.length))
          } else {
            this.dataForm.images = []
            this.dataForm.imageList = []
          }
          const target = this.carList.find(c => c.id === this.dataForm.carId)
          this.dataForm.licensePlate = target.licensePlate
          this.dataForm.carSize = target.carSize
          const res = await this.$http({
            url: this.$http.adornUrl('/car/filling/operationRecord'),
            method: 'post',
            data: id
          })
          this.records = res.datas.map(d => {
            if (d.status === 3) {
              return `${d.createTime} ${d.nickName}进行了审批驳回操作，备注内容：${d.remarks}。`
            }
            return `${d.createTime} ${d.nickName}进行了${operationTypes[d.typeCode]}操作。`
          })
          this.loading = false
        }
      }
    },

    abortMethod (value) {
      return this.$http({
        url: this.$http.adornUrl('/car/filling/review'),
        method: 'post',
        data: {
          id: this.$route.query.id,
          status: 3,
          remarks: value
        }
      })
    },

    verifyMethod () {
      return this.$http({
        url: this.$http.adornUrl('/car/filling/review'),
        method: 'post',
        data: {
          id: this.$route.query.id,
          status: 1
        }
      })
    }
  }
}
</script>
